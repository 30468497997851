'use client'

import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
  signOut,
  type NextOrObserver,
  type User,
} from 'firebase/auth';
import { config } from './firebaseConfig';

export const initFirebase = () => {
  const app = initializeApp(config);
  const auth = getAuth(app);

  const googleAuthProvider = new GoogleAuthProvider();
  googleAuthProvider.setCustomParameters({ prompt: 'select_account' });

  const signInWithGoogle = () => signInWithPopup(auth, googleAuthProvider);

  const signInWithGoogleCredential = async (token: string) => {
    const credential = GoogleAuthProvider.credential(token);
    return signInWithCredential(auth, credential);
  };

  const userStateListener = (callback: NextOrObserver<User>) =>
    onAuthStateChanged(auth, callback);

  const signOutUser = async () => signOut(auth);

  return {
    signInWithGoogle,
    signInWithGoogleCredential,
    userStateListener,
    signOutUser,
  };
};
