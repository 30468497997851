import { type FirebaseOptions } from "firebase/app";

export const config: FirebaseOptions = {
  apiKey: "AIzaSyAbLy_s6hJqVNr2ZN0UHHiCbJX1X8smTws",
  authDomain: "auth.character.ai",
  projectId: "character-ai",
  storageBucket: "character-ai.appspot.com",
  messagingSenderId: "458797720674",
  appId: "1:458797720674:web:b334ca4361e934daeb9d3b",
  measurementId: "G-3182PBRZJV",
};
