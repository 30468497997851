"use client";

import { useEffect } from "react";

export default function RedirectToCharacter() {
  useEffect(() => {
    window.location.replace("https://character.ai");
  }, []);

  return null;
}
