"use client";

import DevSignIn from "@src/components/DevSignIn";
import RedirectToCharacter from "@src/components/RedirectToCharacter";
import { api } from "@src/lib/api";
import { isProductionEnv } from "@src/env/client";

function Login() {
  return (
    <main className="min-h-screen overflow-y-scroll flex flex-col items-center justify-between p-24 gap-24">
      {isProductionEnv() ? (
        <RedirectToCharacter />
      ) : (
        <>
          <div>
            <h1>Annotation Platform (Dev Mode)</h1>
          </div>
          <div>
            <DevSignIn />
          </div>
        </>
      )}
    </main>
  );
}

export default api.withTRPC(Login);
