import { api } from "@src/lib/api";
import { initFirebase } from "@src/lib/auth";
import { useCallback } from "react";

export function useAuthLogout() {
  const logoutMutation = api.auth.logout.useMutation();

  return async function logout() {
    await logoutMutation.mutateAsync();
    window.location.reload();
  };
}

export function useAuthSignIn() {
  const login = api.auth.signIn.useMutation();
  const onPressGoogle = useCallback(async () => {
    try {
      const credentials = await initFirebase().signInWithGoogle();
      const googleJwt = await credentials.user.getIdToken();

      const successfulLogin = await login.mutateAsync({
        googleJwt,
      });

      if (successfulLogin) {
        window.location.assign("/");
      } else {
        throw new Error("Failed to exchange JWT for django token");
      }
    } catch (error: any) {
      console.error(error);
    }
  }, [login]);

  return {
    onPressGoogle,
  };
}
