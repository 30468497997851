"use client";

import { useAuthSignIn } from "@src/hooks/useAuthSignIn";
import { Button } from "@/components/ui/button";
export default function DevSignIn() {
  const { onPressGoogle } = useAuthSignIn();

  return (
    <div>
      <Button variant="primary" className="w-full" onPress={onPressGoogle}>
        Sign in with google
      </Button>
    </div>
  );
}
